import Script from 'next/script';

const ZendeskScript = () => {
  return (
    <>
      {/* Improves initial page load performance in Lighthouse, allegedly */}
      <Script
        async
        id="ze-disable-connect-on-page-load"
        dangerouslySetInnerHTML={{
          __html: `
          window.zESettings = {
            webWidget: {
              chat: {
                connectOnPageLoad: false,
              },
            },
          };
      `,
        }}
      ></Script>
      <Script
        defer
        id="ze-snippet"
        async
        src="https://static.zdassets.com/ekr/snippet.js?key=ef89f209-591e-4a1d-b1b7-6429fb2e9661"
        strategy="worker"
      ></Script>
    </>
  );
};

export default ZendeskScript;
