import React, { createContext, useContext, useState } from 'react';

export interface FormQuestionsInitial {
  answerId: number;
  questionId:number;
}

interface FormQuestionsInitialContextType {
  formQuestionsInitial: FormQuestionsInitial[];
  setFormQuestionsInitial: React.Dispatch<React.SetStateAction<FormQuestionsInitial[]>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  handlePrevQuestion: () => void; // Agregar la función aquí
}

const FormContext = createContext<FormQuestionsInitialContextType | undefined>(undefined);

export const useFormContext = (): FormQuestionsInitialContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

export const FormProvider: React.FC = ({ children }) => {
  const [formQuestionsInitial, setFormQuestionsInitial] = useState<FormQuestionsInitial[]>([]);
  const [step, setStep] = useState<number>(0);

  const handlePrevQuestion = () => {
    if (step === 0) {
      return;
    }
    setStep(step - 1);
  };

  return (
    <FormContext.Provider value={{ formQuestionsInitial, setFormQuestionsInitial, step, setStep, handlePrevQuestion }}>
      {children}
    </FormContext.Provider>
  );
};
