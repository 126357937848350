module.exports = {
  mode: 'jit',
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '16px',
        sm: '2rem',
        lg: '4rem',
        xl: '5rem',
        '2xl': '6rem',
      },
    },
    fontFamily: {
      body: ['WorkSans', '"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'],
      heading: ['VanguardCF', 'sans-serif'],
      'bebas-neue': ['Bebas Neue', 'sans-serif'],
      'work-sans': ['Work Sans', 'sans-serif'],
    },
    extend: {
      aspectRatio: {
        banner: '270 / 134',
      },
      colors: {
        black: '#000000',
        berry: '#501639',
        gray: {
          'ultra-light': '#f0f0f0',
          light: '#d0d0d0',
          DEFAULT: '#b4b4b4',
          dark: '#979797',
        },
        apricot: {
          DEFAULT: '#de5a56',
          dark: '#b3674c',
        },
        orange: '#ec7951',
        albescent: '#f6e8d8',
        cacao: '#312525',
        suma: '#C53230',
        'suma-dark': '#991106',
        'suma-light': '#F0474A',
        'suma-blue': '#4fb1ca',
        'suma-blue-dark': '#27314A',
        'suma-green': '#a5c993',
        'suma-white': '#fdfdfd',
        'text-suma-red': '#de5a56',
        'ocean-blue': '#1D2840',
        main: '#EBF3F8',
        cta: '#DD5A57',
        malt: '#e5dfd8',
        cream: '#eee8e2',
        alabaster: '#f0e9e2',
        white: '#ffffff',
        pampas: '#f6f5f3',
        success: '#c9dfb6',
        mustard: '#dfa821',
        error: '#d6564f',
        warn: '#ffc022',
        highlight: '#f39b7d',
        'random-green-1': '#84ac60',
        'random-green-2': '#eff4e9',
        'random-gray-1': '#fdfcfa',
        'random-gray-2': '#efe8e2',
        'random-gray-3': '#f6f2ee',
        'random-gray-4': '#b9b3ac',
        'random-tan-1': '#f9e8d6',
        'random-tan-2': '#fef0e2',
        'random-tan-3': '#f4efea',
        'random-apricot-1': '#fff6ed',
        'random-apricot-2': '#f3a87c',
        'random-red-1': '#ffebe6',
        'state-poor': '#FF4F41',
        'state-ok': '#FCC435',
        'state-excellent': '#21D4A9',
        widget: {
          need: '#16CAE2',
          want: '#FCC400',
          other: '#1584F2',
          subscription: '#FF9315',
          income: '#ffffff',
        },

        //new design system
        sumawealth: {
          red: {
            100: '#B03D30',
            80: '#C84637',
            60: '#D16357',
            40: '#E19B93',
            20: '#F4DAD7',
          },
          orange:{
            60: '#FF9315'
          },
          blue: {
            100: '#2A3651',
            80: '#3E5079',
            60: '#6178AE',
            40: '#8C9DC4',
            20: '#C2CBE0',
          },
          yellow: {
            100: '#E5AB1A',
            80: '#F3C146',
            60: '#F6CF6F',
            40: '#FCE89C',
            20: '#FDF4CE',
          },
          'sky-blue': {
            100: '#0F6880',
            80: '#17A1C4',
            60: '#46C8E9',
            40: '#92DEF2',
            20: '#DFF6FB',
          },
          success: {
            100: '#095D2E',
            80: '#0D8C45',
            60: '#13CE66',
            40: '#6AF1A6',
            20: '#DAFCE9',
          },
          warning: {
            100: '#705300',
            80: '#CC9600',
            60: '#FFC933',
            40: '#FFDF85',
            20: '#FFF4D6',
          },
          info: {
            100: '#002F66',
            80: '#0055B8',
            60: '#1481FF',
            40: '#8FC3FF',
            20: '#D6E9FF',
          },
          error: {
            100: '#660000',
            80: '#D60000',
            60: '#FF3333',
            40: '#FF7A7A',
            20: '#FFD6D6',
          },
          grey: {
            100: '#7B8794',
            80: '#97A1AB',
            60: '#B3BAC1',
            40: '#CFD4D8',
            20: '#EBEDEF',
          },
          neutral: {
            100: '#000000',
            0: '#FFFFFF',
          },
          facebook: {
            100: '#0866FF'
          },
          gamification: {
            100: '#DFEDF6'
          },
          wishli: {
            20: '#EEF0F1'
          },
          linkedin: {
            100: '#0A66C2'
          },
        },
      },
      borderColor: {
        'state-poor': '#FF4F41',
        'state-ok': '#FCC435',
        'state-excellent': '#21D4A9',
      },
      shadow: {
        DEFAULT: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      },
      fontSize: {
        xxs: '0.5625rem',
      },
      animation: {
        'spin-fast': 'spin 0.25s linear infinite',
      },
      animationIterationCount: {
        2: '2',
      },
      rotate: {
        24: '24deg',
        12: '12deg',
        8: '8deg',
        2: '2deg',
      },
      scale: {
        9: '0.9',
      },
    },
  },
  corePlugins: {
    aspectRatio: true,
  },
  variants: {
    extend: {},
  },
  plugins: [require('@tailwindcss/aspect-ratio'), require('tailwindcss-animate')],
};
