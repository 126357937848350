import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Typography from '../Typography';
import cn from 'classnames';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useTimeoutFn } from 'react-use';

export interface NotificationProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  message: React.ReactNode;
  disappearAfter?: number;
  onClose: AnyFunction;
  onClick?: AnyFunction;
  classNames?: {
    background?: string;
    title?: string;
    message?: string;
    closeButton?: string;
  };
}

const Notification: React.FC<NotificationProps> = ({
  icon,
  onClose,
  title,
  message,
  disappearAfter,
  onClick,
  classNames,
}) => {
  useTimeoutFn(onClose, disappearAfter || 30000);

  return (
    <div
      className={cn(
        'max-w-screen ml-4 flex flex-nowrap items-center justify-between gap-3 py-2 pr-3 pl-4 shadow sm:max-w-lg md:py-4 md:pl-6',
        classNames?.background,
        {
          'cursor-pointer': onClick !== undefined,
        }
      )}
    >
      <div
        className="flex flex-nowrap items-center gap-3"
        onClick={
          onClick
            ? () => {
                onClick();
                onClose();
              }
            : undefined
        }
      >
        {icon}
        <div className="flex flex-col gap-1">
          <Typography
            variant="body-small"
            className={cn('font-medium leading-none', classNames?.title)}
          >
            {title}
          </Typography>
          <Typography variant="body-small" className={cn('leading-none', classNames?.message)}>
            {message}
          </Typography>
        </div>
      </div>

      <FontAwesomeIcon
        icon={faTimes}
        onClick={onClose}
        className={cn('fa-lg cursor-pointer p-4', classNames?.closeButton)}
      />
    </div>
  );
};

export default Notification;
