import {
  // CUSTOM_REEL_LOCAL_STORAGE_KEY,
  ENVIRONMENT,
  // PDP_LOCAL_STORAGE_KEY,
  // REEL_TOOLBOX_LOCAL_STORAGE_KEY,
} from '@/utils/constants';

import { QueryClient } from 'react-query';

export function getAppBaseUrl() {
  if (isBrowser()) {
    return '';
  }

  return (
    process.env.NEXT_PUBLIC_APP_BASE_URL ||
    (process.env.VERCEL_URL && `https://${process.env.VERCEL_URL}`) ||
    `http://localhost:${process.env.PORT ?? 3000}`
  );
}

export function isDevelopment() {
  return getEnvName() === ENVIRONMENT.dev;
}

export function isTest() {
  return getEnvName() === ENVIRONMENT.test;
}

export function isProd() {
  return getEnvName() === ENVIRONMENT.prod;
}

export function isServer() {
  return typeof window === 'undefined';
}

export function isBrowser() {
  return typeof window === 'object';
}

export function getEnvName() {
  const branch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF;

  // undefined on client and empty string on server
  if (branch === undefined || branch === '') return ENVIRONMENT.dev;
  if (branch === 'main') return ENVIRONMENT.prod;

  return branch;
}

export async function clearAppState({
  queryClient,
  executeQueryClientCache = false,
}: {
  queryClient: QueryClient;
  executeQueryClientCache?: boolean;
}) {
  if (executeQueryClientCache) {
    await Promise.all([
      queryClient.cancelQueries(),
      queryClient.cancelMutations(),
      queryClient.clear(),
    ]);
  }

  await Promise.all([localStorage.clear(), window.analytics?.reset()]);
}

export function getEnvironmentBaseUrl () {
  if(isProd()){ 
    return "https://app.sumawealth.io"
  } else if(isDevelopment()){
    return "https://development.sumawealth.io"
  }else{
    return "https://development.sumawealth.io"
  }
}