import type { TagProps } from '@/components/molecules/Tag';
import { addDays } from 'date-fns';
import brands from '../../data/shopstyle-brands.json';
import tailwindConfig from '../../tailwind.config';
import { formatCurrencyNotCent } from './format';

export const BENJI_IMAGE_URL = '/images/Savings-Summary-Icon.svg';

export const CASHOUT_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfIkkDzhvKyXwDtSps9JHWPMqelGKMUSXRW4kiR27RaFozsKw/viewform';
export const TALON_ONE_BASE_PATH = 'https://reel.us-east4.talon.one';

export const MIN_GOAL_COMPLETION_DURATION_IN_DAYS = 7;
export const MAX_GOAL_COMPLETION_DURATION_IN_DAYS = 365;
export const MIN_TARGET_COMPLETION_DATE = addDays(new Date(), MIN_GOAL_COMPLETION_DURATION_IN_DAYS);
export const MAXIMUM_TARGET_COMPLETION_DATE = addDays(
  new Date(),
  MAX_GOAL_COMPLETION_DURATION_IN_DAYS
);
export const MAX_IMAGE_UPLOAD_SIZE = 10000000;
export const MIN_GOAL_AMOUNT = 3000;
export const MIN_BOOST_AMOUNT = 100;
export const FRAUD_MAX_DAILY_BOOSTS = 2;
export const FRAUD_MAX_DAILY_CHIP_INS = 3;
export const FRAUD_MAX_CHIP_IN_AMOUNT = 50000;
export const FRAUD_MAX_DAILY_CHIP_IN_COUNT_BY_FUNDING_SOURCE = 1;
export const FRAUD_MAX_CONCURRENT_ACTIVE_GOALS = 5;
export const FRAUD_MIN_REEL_DURATION_IN_DAYS = 15;
export const FRAUD_MAX_SINGLE_BOOST_AMOUNT = 50000;
export const FRAUD_MAX_TRANSFERS_PER_GOAL = 3;

export const SECONDS_IN_DAY = 86400;

export const DEFAULT_SEARCH_PAGE_SIZE = 20;

export const VARIANT_MAPPER: { [key: string]: TagProps['variant'] } = {
  Default: 'primary',
  Wants: 'want',
  Others: 'other',
  Subscriptions: 'subscription',
  Needs: 'need',
};

export const FREQUENCY_OPTIONS: Frequency[] = [
  /*{
    duration: 1,
    descriptor: 'daily',
  },*/
  {
    duration: 7,
    descriptor: 'weekly',
  },
  {
    duration: 14,
    descriptor: 'bi-weekly',
  },
  {
    duration: 28,
    descriptor: 'monthly',
  },
];

export const FREQUENCY_HIDE_OPTIONS: Frequency[] = [
  {
    duration: 1,
    descriptor: 'daily',
  },
];

export const INSTALLMENT_AMOUNT_RANGES = [
  [100, 500],
  [500, 1000],
  [1000, 2000],
  [2000, 4000],
  [4000, 6000],
  [6000, 8000],
  [8000, 10000],
  [10000, 12500],
  [12500, 15000],
  [15000, 20000],
  [20000, 25000],
  [25000, 35000],
  [35000, 50000],
  [50000, 75000],
  [75000, 100000],
  [100000, 150000],
  [150000, 250000],
  [250000, 500000],
  [500000, 1000000],
  [1000000, 100000000],
] as const;

// This doesn't have complete parity with the legacy types,
// I added a few new types so I could do some QOL
// translations over here
export const LEGACY_TRANSACTION_TYPES = [
  'debit',
  'transfer',
  'contribution',
  'savings',
  'boost',
  'from-wallet',
  'to-wallet',
  'oversaved',
  'rewards',
  'coupon',
  'deducted-by-reel',
  'chip-in',
] as const;

// This is a slightly different set of values that need to be passed
// to the legacy transaction API's "create transaction" endpoint
export const LEGACY_TRANSACTION_API_CREATE_ENDPOINT_TRANSACTION_TYPES = [
  'transfer',
  'transfer-to-wallet',
  'transfer-from-wallet',
  'oversaved-auto-transfer-to-wallet',
  'credit',
  'debit',
  'boost',
] as const;

export const LEGACY_REEL_STATUSES = [
  'archived',
  'paused',
  'done',
  'active',
  'processing',
  'complete',
  'completed-with-pending',
  'confirmed',
  'pending-product-review',
  'social-terminated',
];

export const LEGACY_STATUS_REASONS_INELIGIBLE_FOR_CASHOUT: (typeof LEGACY_REEL_STATUS_REASONS)[number][] =
  [
    'amount-transferred',
    'cash-out-processed',
    'cash-out-requested',
    'disputed-charges',
    'exchanged-complete',
    'exchanged-in-process',
    'flagged-fraud',
    'refunded',
    'return-cashout-processed',
    'return-cashout-requested',
    'return-transfered-to-reel',
    'sold-out-cash-out-processed',
    'sold-out-cash-out-requested',
    'UnArchived',
  ];

export const LEGACY_REEL_STATUS_REASONS = [
  'amount-transferred',
  'cash-out-processed',
  'cash-out-requested',
  'deleted-by-user',
  'disputed-charges',
  'dont-want-it',
  'exchanged-complete',
  'exchanged-in-process',
  'external-product-rejected',
  'flagged-fraud',
  'for-another-reel',
  'funding-source-deleted',
  'insufficient-funds',
  'need-money',
  'other',
  'payment-failed',
  'plaid-auth-required',
  'price-discrepancy',
  'refunded',
  'resume-later',
  'return-cashout-processed',
  'return-cashout-requested',
  'return-transfered-to-reel',
  'save-for-later',
  'sold-out-cash-out-processed',
  'sold-out-cash-out-requested',
  'sold-out-pending-customer-response',
  'started-accidentally',
  'UnArchived',
  'updated-by-script',
  'user-resumed',
] as const;

export const GOAL_REQUIRED_ACTIONS = [
  'authorize-plaid',
  'connect-funding-source',
  'address-debit-card-failures',
  'delete-invalid-funding-source',
  'address-consecutive-overdraft-preventions',
];

export const PAYMENT_PROCESSOR_EVENTS = [
  'Savings Plan Ignored',
  'Savings Plan Activated',
  'Savings Plan Charged',
  'Savings Plan Paused',
  'Payment Engine Failed',
];

export const TRANSFER_EVENTS = ['Goal-to-Goal Transfer Created', 'Wallet-to-Goal Transfer Created'];

export const THEME_COLORS = tailwindConfig.theme.extend.colors;

export const REEL_USER_LOCAL_STORAGE_KEY = 'reel-user';
export const CUSTOM_REEL_LOCAL_STORAGE_KEY = 'custom-reel-form-state';
export const PDP_LOCAL_STORAGE_KEY = 'pdp-form-state';
export const REEL_TOOLBOX_LOCAL_STORAGE_KEY = 'reel-toolbox-state';
export enum ENVIRONMENT {
  prod = 'production',
  test = 'test',
  dev = 'development',
}

export const SEARCH_FILTER_TYPES = ['Women', 'Men', 'Kids', 'Home'] as const;
export type SearchFilterType = (typeof SEARCH_FILTER_TYPES)[number];

export const REDEEMABLE_REWARD_VALUES = [500, 1000, 2500, 3000, 5000];

// Pared down list from http://api.shopstyle.com/api/v2/brands
export const SEARCH_FILTER_SHOPSTYLE_BRANDS = brands;

// Copy-pasted from http://api.shopstyle.com/api/v2/priceFilters
export const SEARCH_FILTER_SHOPSTYLE_PRICE_RANGES = [
  {
    id: '7',
    name: '$0 – $25',
    longLabel: '$0 – $25',
    urlIdentifier: '7',
    minPrice: 0,
    maxPrice: 2500,
    minPriceLabel: '$0',
  },
  {
    id: '8',
    name: '$25 – $50',
    longLabel: '$25 – $50',
    urlIdentifier: '8',
    minPrice: 2500,
    maxPrice: 5000,
    minPriceLabel: '$25',
  },
  {
    id: '9',
    name: '$50 – $100',
    longLabel: '$50 – $100',
    urlIdentifier: '9',
    minPrice: 5000,
    maxPrice: 10000,
    minPriceLabel: '$50',
  },
  {
    id: '10',
    name: '$100 – $150',
    longLabel: '$100 – $150',
    urlIdentifier: '10',
    minPrice: 10000,
    maxPrice: 15000,
    minPriceLabel: '$100',
  },
  {
    id: '11',
    name: '$150 – $250',
    longLabel: '$150 – $250',
    urlIdentifier: '11',
    minPrice: 15000,
    maxPrice: 25000,
    minPriceLabel: '$150',
  },
  {
    id: '12',
    name: '$250 – $500',
    longLabel: '$250 – $500',
    urlIdentifier: '12',
    minPrice: 25000,
    maxPrice: 50000,
    minPriceLabel: '$250',
  },
  {
    id: '13',
    name: '$500 – $1,000',
    longLabel: '$500 – $1,000',
    urlIdentifier: '13',
    minPrice: 50000,
    maxPrice: 100000,
    minPriceLabel: '$500',
  },
  {
    id: '14',
    name: '$1,000 – $2,500',
    longLabel: '$1,000 – $2,500',
    urlIdentifier: '14',
    minPrice: 100000,
    maxPrice: 250000,
    minPriceLabel: '$1,000',
  },
  {
    id: '15',
    name: '$2,500 – $5,000',
    longLabel: '$2,500 – $5,000',
    urlIdentifier: '15',
    minPrice: 250000,
    maxPrice: 500000,
    minPriceLabel: '$2,500',
  },
  {
    id: '16',
    name: '$5,000+',
    longLabel: '$5,000+',
    urlIdentifier: '16',
    minPrice: 500000,
    maxPrice: 2147483647,
    minPriceLabel: '$5,000',
  },
];

export const IMPERSONATION_HEADER_NAME = 'x-impersonated-user';

export const CUSTOMER_SUCCESS_EMAIL = 'hola@wearesuma.com';
export const HELP_CENTER_URL = 'https://help.joinreel.com/hc/en-us';

// Footer URLs
export const WHAT_WE_DO_URL = 'https://sumawealth.com/what-we-do';
export const RENT_OR_HOMEOWNER_URL = 'https://sumawealth.com/tools/home-rent-vs-buy';
export const GROW_YOUR_SAVINGS_URL = 'https://sumawealth.com/tools/savings';
export const PAY_YOUR_CREDIT_CARD_URL = 'https://sumawealth.com/tools/credit-card-payoff';
export const FREELANCE_OR_SELF_EMPLOYED_URL =
  'https://sumawealth.com/tools/incorporate-vs-freelance';
export const WHAT_IS_SUMA_URL = 'https://sumawealth.com/what-is-suma';

export const TERMS_AND_CONDITIONS_URL = 'https://sumawealth.com/terms';
export const PRIVACY_POLICY_URL = 'https://sumawealth.com/privacy-policy';
export const ACCESIBILITY_STATEMENT_URL = 'https://sumawealth.com/accesibility';
export const MASTERCARD_OFFER_TERMS_URL = 'https://sumawealth.com/mastercard-terms-and-conditions';

export interface InsightsDashboardExpensesColors {
  [key: string]: string;
}

// Colors used in expenses widget
export const INSIGHTS_DASHBOARD_EXPENSES_COLORS: InsightsDashboardExpensesColors = {
  needs: tailwindConfig.theme.extend.colors.widget.need,
  wants: tailwindConfig.theme.extend.colors.widget.want,
  others: tailwindConfig.theme.extend.colors.widget.other,
  subscriptions: tailwindConfig.theme.extend.colors.widget.subscription,
  income: tailwindConfig.theme.extend.colors.widget.income,
};

export const INSIGHTS_DASHBOARD_IMAGES_COLORS = {
  statePoor: '#FF4F41',
  stateOk: '#FCC435',
  stateExcellent: '#21D4A9',
};

export const CARD_PROCESSING_FEE = 0.3;
export const CARD_PROCESSING_FEE_HUMANIZED = '%3';

export const WEALTH_BUILDING_EXPENSES_LOCK_WIDGET = {
  expenses: {
    id: 'expensesWidget',
    title: 'YOUR SPENDING',
    buttonTitle: 'View Breakdown',
    hoverTitle: 'View Breakdown',
    extraData: {
      overview: {
        needs: 'Needs',
        title: 'Overview',
        wants: 'Wants',
        income: 'Income',
        others: 'Others',
        subscriptions: 'Subscriptions',
      },
    },
  },
  data: {
    '1': '23',
    index: 22,
    transactions60: {
      others: {
        amount: 0,
        percentage: 0,
      },
      wants: {
        amount: 0,
        percentage: 0,
      },
      subscriptions: {
        amount: 0,
        percentage: 0,
      },
      income: {
        amount: 0,
      },
      needs: {
        amount: 0,
        percentage: 0,
      },
      total: '0.00',
    },
    transactionsLastmonth: {
      others: {
        amount: 0,
        percentage: 0,
      },
      wants: {
        amount: 0,
        percentage: 0,
      },
      subscriptions: {
        amount: 0,
        percentage: 0,
      },
      income: {
        amount: 0,
      },
      needs: {
        amount: 0,
        percentage: 0,
      },
      total: '0.00',
    },
    transactionsLastlastmonth: {
      others: {
        amount: 0,
        percentage: 0,
      },
      wants: {
        amount: 0,
        percentage: 0,
      },
      subscriptions: {
        amount: 0,
        percentage: 0,
      },
      income: {
        amount: 0,
      },
      needs: {
        amount: 0,
        percentage: 0,
      },
      total: '0.00',
    },
    transactions: {
      others: {
        amount: 0,
        percentage: 0,
      },
      wants: {
        amount: 0,
        percentage: 0,
      },
      subscriptions: {
        amount: 0,
        percentage: 0,
      },
      income: {
        amount: 0,
      },
      needs: {
        amount: 0,
        percentage: 0,
      },
      total: '0.00',
    },
    needs: 0,
    balance: 0,
    userId: 'fadfb4867f63b2aeb23e0ec0229d2c74',
    transactions7: {
      others: {
        amount: 0,
        percentage: 0,
      },
      wants: {
        amount: 0,
        percentage: 0,
      },
      subscriptions: {
        amount: 0,
        percentage: 0,
      },
      income: {
        amount: 0,
      },
      needs: {
        amount: 0,
        percentage: 0,
      },
      total: '0.00',
    },
    transactionsThismonth: {
      others: {
        amount: 0,
        percentage: 0,
      },
      wants: {
        amount: 0,
        percentage: 0,
      },
      subscriptions: {
        amount: 0,
        percentage: 0,
      },
      income: {
        amount: 0,
      },
      needs: {
        amount: 0,
        percentage: 0,
      },
      total: '0.00',
    },
    emergencyFundsTime: '{}',
  },
};

export const WEALTH_BUILDING_INSIGHTS_LOCK_WIDGET = {
  data: [],
  insights: {
    id: 'insightsWidget',
    title: 'FIND FREE MONEY',
    buttonTitle: 'Find Free Money',
    hoverTitle: 'Insights',
    extraData: {
      empty: 'We’ll take a deep dive into your finances and free up money you never knew existed.',
      weFound: 'We Found',
      yourSpent: 'Your Spent',
    },
  },
};

export const WEALTH_BUILDING_FOR_EMERGENCY_LOCK_WIDGET = {
  forEmergency: {
    id: 'forEmergencyWidget',
    title: 'EMERGENCY SAVINGS',
    hoverTitle: 'For Emergency',
    extraData: {
      description: 'Most experts recommend on emergency funds 3-6 months’ worth expenses.',
      description_title: "Bet you didn't know",
    },
  },
  data: {
    amount: 0,
    percentage: '{}',
  },
};

// DUMMIES
export const WEALTH_BUILDING_EXPENSES_DUMMY_WIDGET = {
  expenses: {
    id: 'expensesWidget',
    title: 'YOUR SPENDING',
    buttonTitle: 'View Breakdown',
    hoverTitle: 'View Breakdown',
    extraData: {
      overview: {
        needs: 'Needs',
        title: 'Overview',
        wants: 'Wants',
        income: 'Income',
        others: 'Others',
        subscriptions: 'Subscriptions',
      },
    },
  },
  data: {
    userId: 2183,
    savings: 1000,
    balance: 2002,
    emergencyFundsTimeLastThreeMonths: 0.8015004087652084,
    currentMonth: {
      numbOfTransactions: 96,
      startDate: {
        time: 1711929600,
        toString: '2024-04-01',
      },
      endDate: {
        time: 1712880000,
        toString: '2024-04-12',
      },
      titleRange: 'currentMonth',
      income: 0,
      otherIncome: 1477.54,
      totalIncome: 92.62,
      emergencyFundsTime: 3.0988534242330332,
      categories: {
        needs: {
          amount: 322.70000000000005,
          percentage: 14.90930595725414,
        },
        others: {
          amount: 415.61,
          percentage: 19.201910904537936,
        },
        subscriptions: {
          amount: 0,
          percentage: 0,
        },
        wants: {
          amount: 1426.1100000000004,
          percentage: 65.88878313820794,
        },
      },
      expenses: 2164.42,
    },
    lastSevenDays: {
      numbOfTransactions: 64,
      startDate: {
        time: 1712275200,
        toString: '2024-04-05',
      },
      endDate: {
        time: 1712880000,
        toString: '2024-04-12',
      },
      titleRange: 'lastSevenDays',
      income: 0,
      otherIncome: 1161.28,
      totalIncome: 24.89,
      emergencyFundsTime: 4.013163175214705,
      categories: {
        needs: {
          amount: 249.18,
          percentage: 16.90731442529515,
        },
        others: {
          amount: 273.8,
          percentage: 18.577826027954945,
        },
        subscriptions: {
          amount: 0,
          percentage: 0,
        },
        wants: {
          amount: 950.82,
          percentage: 64.51485954674989,
        },
      },
      expenses: 1473.8000000000002,
    },
    lastMonth: {
      numbOfTransactions: 248,
      startDate: {
        time: 1709251200,
        toString: '2024-03-01',
      },
      endDate: {
        time: 1711843200,
        toString: '2024-03-31',
      },
      titleRange: 'lastMonth',
      income: 0,
      otherIncome: 3353.88,
      totalIncome: 140.31,
      emergencyFundsTime: 0.8015004087652084,
      categories: {
        needs: {
          amount: 1247.66,
          percentage: 25.75213421178646,
        },
        others: {
          amount: 1398.6799999999996,
          percentage: 28.869239279404223,
        },
        subscriptions: {
          amount: 33.75,
          percentage: 0.6966116807846632,
        },
        wants: {
          amount: 2164.7899999999995,
          percentage: 44.68201482802462,
        },
      },
      expenses: 4844.880000000001,
    },
    lastTwoMonths: {
      numbOfTransactions: 482,
      startDate: {
        time: 1706745600,
        toString: '2024-02-01',
      },
      endDate: {
        time: 1711843200,
        toString: '2024-03-31',
      },
      titleRange: 'lastTwoMonths',
      income: 2025,
      otherIncome: 6801.110000000002,
      totalIncome: 2478.63,
      emergencyFundsTime: 0.43655159166710306,
      categories: {
        needs: {
          amount: 2290.6800000000007,
          percentage: 25.076740509793467,
        },
        others: {
          amount: 2582.4,
          percentage: 28.270284235463105,
        },
        subscriptions: {
          amount: 33.75,
          percentage: 0.3694710706888475,
        },
        wants: {
          amount: 4227.8499999999985,
          percentage: 46.283504184054614,
        },
      },
      expenses: 9134.679999999997,
    },
    lastThreeMonths: {
      numbOfTransactions: 626,
      startDate: {
        time: 1704067200,
        toString: '2024-01-01',
      },
      endDate: {
        time: 1711843200,
        toString: '2024-03-31',
      },
      titleRange: 'lastThreeMonths',
      income: 2025,
      otherIncome: 8946.600000000002,
      totalIncome: 2604.3800000000006,
      emergencyFundsTime: 0.3395770228603252,
      categories: {
        needs: {
          amount: 2944.8399999999997,
          percentage: 23.86716008318707,
        },
        others: {
          amount: 3553.4999999999995,
          percentage: 28.8001906234652,
        },
        subscriptions: {
          amount: 33.75,
          percentage: 0.27353494682480667,
        },
        wants: {
          amount: 5806.37,
          percentage: 47.059114346523046,
        },
      },
      expenses: 12338.459999999985,
    },
  },
};

export const WEALTH_BUILDING_INSIGHTS_DUMMY_WIDGET = {
  data: [
    {
      favorite: false,
      hidden: false,
      helpful: false,
      id: '140235',
      createdAt: null,
      updatedAt: '2023-08-31T22:25:41.357Z',
      deletedAt: null,
      accountGuid: null,
      amount: 13.7,
      category: 'Rental Car & Taxi',
      checkNumberString: null,
      currencyCode: null,
      date: '2023-08-30T22:31:37.254Z',
      description: null,
      transactionId: 'TRN-7c2ce603-a2f6-458c-bc9b-45392f655b9b',
      isBillPay: false,
      isDirectDeposit: false,
      isExpense: false,
      isFee: false,
      isIncome: false,
      isInternational: false,
      isOverdraftFee: false,
      isPayrollAdvance: false,
      isSubscription: false,
      latitude: null,
      localizedDescription: null,
      localizedMemo: null,
      longitude: null,
      memberGuid: null,
      memo: null,
      merchantCategoryCode: null,
      merchantGuid: null,
      originalDescription: null,
      postedAt: null,
      status: null,
      topLevelCategory: null,
      transactedAt: '2023-08-17T00:00:00.000Z',
      type: null,
      userId: null,
      finsightImage:
        'https://imagedelivery.net/-8TGKEbX2Cm_PumlsBwqtg/01b09345-1787-4bb7-cbc8-7eeb4147b300/public',
      insightCtaurl: 'https://links.fingoal.com/Pf4V2V',
      insightText:
        'Not sure whether Lyft or Uber will get you the best rate? Obi lets you compare the price of all major ride options.',
      recommendation: null,
      finsightDate: null,
      amountFound: 12,
      insightTextHash: '54c95ffde7f153f5ee19e9ec77f0f61047cc908d9a400f747a1c0457a7bc668e',
      recommendationHash: '3405ffa167440a5707ed258f6ee66ac9e2f391e9a9287f96c47078a07497a7d8',
      textHash: '91ddc3babaf4dab380a4823da7a6e84e04b52db66639877c7ae958a79effd954',
      finsightHash: '94a1bd40e06c5285b41f2ab383c689df96522ec05365403bf5e1fa7e62aef1fe',
      uid: 'e506a6e411c71ee1c1761bf666568a54',
      estimatedSavingsType: 4,
      sumaCategory: ['Wants'],
      enrichedTransaction: null,
    },
  ],
  insights: {
    id: 'insightsWidget',
    title: 'FIND FREE MONEY',
    buttonTitle: 'Find Free Money',
    hoverTitle: 'Insights',
    extraData: {
      empty: 'We’ll take a deep dive into your finances and free up money you never knew existed.',
      weFound: 'We Found',
      yourSpent: 'Your Spent',
    },
  },
};

export const WEALTH_BUILDING_FOR_EMERGENCY_DUMMY_WIDGET = {
  forEmergency: {
    id: 'forEmergencyWidget',
    title: 'EMERGENCY SAVINGS',
    hoverTitle: 'For Emergency',
    extraData: {
      description:
        'We take into account the last 90 days of your transactions  to calculate your emergency fund. Most experts recommend emergency funds 3-6 months’ worth of needs expenses.',
      description_title: "Bet you didn't know",
    },
  },
  data: {
    // amount: 5000,
    percentage: 6,
  },
};

export const getAmountCouldSaveInsight = (insight: Linker.Finsight) => {
  let couldSaveAmount = insight.amountFound;

  if (couldSaveAmount === 0 || couldSaveAmount < 0) {
    return '$0.00';
  }

  if (insight.estimatedSavingsType && insight.estimatedSavingsType === 1) {
    couldSaveAmount = insight.amountFound;
  }

  if (insight.estimatedSavingsType && insight.estimatedSavingsType === 4) {
    couldSaveAmount = (insight.amountFound * insight.amount) / 100;
  }

  return `${formatCurrencyNotCent(couldSaveAmount, true)}`;
};

export const SVG_ROAD_TOUR_STEPS = [
  {
    id: 'shepherd-mask-fighter',
    title: null,
    text: '<strong>Watch your money grow like the jalapeño seeds that your mom planted in her garden!</strong>',
    attachTo: {
      // element: '.shepherd-mask-fighter',
      // on: 'top',
    },
    attachMobileTo: {}, //Dont show square element
  },
  {
    id: 'expenses',
    title: null,
    text: `We make your <b>money moves as easy to understand as the latest Benito’s tune</b>.<br /><br />Slay your money game by becoming familiar with the categories: <b>needs, wants, others and subcriptions</b>`,
    attachTo: {
      element: '.shepherd-expenses',
      on: 'bottom',
    },
    attachMobileTo: {
      element: '.shepherd-expenses',
      on: 'bottom',
    },
  },
  {
    id: 'insights',
    title: null,
    text: `Just like when you open any butter container at home, <b>Suma’s A.I will find unlimited surprise saving opportunities</b> to potentially make up to thousands of dollars in a month.<br /><br /> Remember to add it to your favs!`,
    attachTo: {
      element: '.shepherd-insights',
      on: 'bottom',
    },
    attachMobileTo: {
      element: '.shepherd-insights',
      on: 'bottom',
    },
  },
  {
    id: 'for-emergency',
    title: null,
    text: `Your abuela’s <b>vaporub won’t protect you from a rainy day</b>, but an emergency savings fund will.<br /><br /><b>Find out if you’re ready and let our A.I</b> give you tips on how to make it better and faster.`,
    attachTo: {
      element: '.shepherd-for-emergency',
      on: 'bottom',
    },
    attachMobileTo: {
      element: '.shepherd-for-emergency',
      on: 'bottom',
    },
  },
  {
    id: 'budget',
    title: null,
    text: `<b>Plan your future spending without telenovela drama</b> using Suma’s A.I Money Management Tool`,
    attachTo: {
      element: '.shepherd-budget',
      on: 'bottom',
    },
    attachMobileTo: {
      element: '.shepherd-budget',
      on: 'bottom',
    },
  },
  {
    id: 'educational-tips',
    title: null,
    text: `<b>Don’t stress out asking "401 ké?"</b>. Get the 411 on money with our free Sumaversity courses`,
    attachTo: {
      element: '.shepherd-educational-tips',
      on: 'bottom',
    },
    attachMobileTo: {
      element: '.shepherd-educational-tips',
      on: 'bottom',
    },
  },
  {
    id: 'special-offers',
    title: null,
    text: `<b>Learn to maximize</b> your bonus bucks using our money calculators.`,
    attachTo: {
      element: '.shepherd-special-offers',
      on: 'bottom',
    },
    attachMobileTo: {
      element: '.shepherd-special-offers',
      on: 'bottom',
    },
  },
];

export const LINKER_TRANSACTIONS_QUEUE_EXECUTE_LOGIN = 'linker_transactions_queue_execute_login';

export const LINKER_INSIGHTS_EXECUTE_LOGIN = 'linker_insights_execute_login';

export const SUMA_TYPE_SUBSCRIPTION_LOCAL_STORAGE = 'suma_type_subscription';

export const SUMA_EMAIL_SUBSCRIPTION_KEY_LOCAL_STORAGE = 'suma_email_subscription';

export const SUMAVERSITY_PLAYSLIST_VIDEOS_CREDIT_COCINA = [
  {
    title: 'Keep Your Balances Under 30%',
    src: 'https://www.youtube.com/embed/2xvLLhF2Ogo?si=4acHQPwqwaNaVObc',
  },
  {
    title: 'Pay All Your Bills on time',
    src: 'https://www.youtube.com/embed/m-2CxskX0GE?si=M1virKzT4R6j7VF3',
  },
  {
    title: 'Pay Your Bills On Time As You Go For That Weekly Taco Run',
    src: 'https://www.youtube.com/embed/iBTGTYYBUew?si=UJTHhuSR2rWGnvGb',
  },
  {
    title: 'Time To Mix It Up!',
    src: 'https://www.youtube.com/embed/wYGnHoULknc?si=Z6ENspFVH4JOMMUh',
  },
];

export const SUMAWEALTH_LOCALE = 'NEXT_LOCALE';
