import React, { useState } from 'react';
import noop from 'lodash/noop';

export const SelectedTabContext = React.createContext<{
  selectedIndex: number;
  setSelectedIndex: AnyFunction;
}>({
  selectedIndex: 0,
  setSelectedIndex: noop,
});

export const SelectedTabProvider: React.FC = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <SelectedTabContext.Provider value={{ selectedIndex, setSelectedIndex }}>
      {children}
    </SelectedTabContext.Provider>
  );
};
