import { ElementType } from 'react';
import cn from 'classnames';
import classNames from 'classnames';

export interface TypographyProps {
  variant?: Variant;
  children: React.ReactNode;
  tag?: ElementType;
  htmlFor?: string;
  className?: string;
}

export type Variant = keyof typeof defaultVariantConfig;

const defaultVariantConfig = {
  heading1: {
    defaultTag: 'h1',
    defaultClassName: 'font-heading text-3xl md:text-5xl font-bold -tracking-[0.016666666em]',
  },
  heading2: {
    defaultTag: 'h1',
    defaultClassName: 'font-heading font-bold text-lg md:text-2xl -tracking-[0.01em]',
  },
  heading3: {
    defaultTag: 'h1',
    defaultClassName: 'font-heading font-bold text-lg md:text-lg -tracking-[0.01em]',
  },
  'body-large': {
    defaultTag: 'span',
    defaultClassName: 'block font-body text-lg md:text-lg',
  },
  body: {
    defaultTag: 'span',
    defaultClassName: 'block font-body text-sm md:text-base',
  },
  'body-small': {
    defaultTag: 'span',
    defaultClassName: 'block font-body text-xs md:text-sm',
  },
  'body-fine-print': {
    defaultTag: 'span',
    defaultClassName: 'block font-body text-xxs md:text-xs',
  },
  'label-large': {
    defaultTag: 'span',
    defaultClassName: 'font-body text-xs md:text-base uppercase tracking-widest',
  },
  label: {
    defaultTag: 'span',
    defaultClassName: 'font-body font-medium text-xxs md:text-sm uppercase tracking-wider',
  },
  link: {
    defaultTag: 'span',
    defaultClassName:
      'font-medium text-xs md:text-sm underline underline-current uppercase tracking-wider text-apricot',
  },
  heading: {
    defaultTag: 'h1',
    defaultClassName: 'font-heading font-thin text-5xl md:text-[3.3em]',
  },
  'heading-widget': {
    defaultTag: 'h1',
    defaultClassName: classNames(
      'font-heading font-normal bg-[#27314a] h-[60px] text-[2.625rem] text-white w-auto h-auto border-t-4 border-l-4 border-r-4 border-solid border-main rounded-t-lg',
      // 'tracking-[0.01rem] leading-relaxed md:leading-[4rem] px-[1rem]'
      'tracking-[0.01rem] leading-snug pb-1 !-p-10 md:leading-[3.7rem] px-[1rem]'
    ),
  },
};

export const Typography = ({
  tag,
  variant = 'body',
  children,
  className,
  htmlFor,
  ...props
}: TypographyProps) => {
  const { defaultTag, defaultClassName } = defaultVariantConfig[variant];

  const Component = tag || defaultTag;

  return (
    <Component className={cn([defaultClassName, className])} htmlFor={htmlFor} {...props}>
      {children}
    </Component>
  );
};
