import { AnimatePresence, m } from 'framer-motion';
import Notification, { NotificationProps } from '@/components/atoms/Notification';
import React, { useEffect, useState } from 'react';
import { useList, useVibrate } from 'react-use';

import { v4 as uuid } from 'uuid';

export interface NotificationData {
  icon?: React.ReactNode;
  title?: string;
  body?: string;
  disappearAfter?: number;
  vibrateOnNotify?: boolean;
  onClick?: AnyFunction;
  classNames?: NotificationProps['classNames'];
}

export const AppNotificationContext = React.createContext({
  notify: (_: NotificationData) => {},
});

export const AppNotificationProvider: React.FC = ({ children }) => {
  const [notifications, { push, removeAt }] = useList<NotificationData & { key: string }>([]);
  const [vibrating, setVibrating] = useState(false);

  useVibrate(vibrating, [100], false);
  useEffect(() => {
    if (vibrating) {
      const timer = setTimeout(() => {
        setVibrating(false);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [vibrating]);

  return (
    <AppNotificationContext.Provider
      value={{
        notify: ({ icon, title, body, disappearAfter, vibrateOnNotify, classNames, onClick }) => {
          push({ icon, title: title, body, disappearAfter, classNames, onClick, key: uuid() });
          vibrateOnNotify && setVibrating(true);
        },
      }}
    >
      {children}

      <div className="fixed bottom-24 right-4 z-50">
        <AnimatePresence>
          {notifications.map((notification) => (
            <m.div
              key={notification.key}
              initial={{ translateX: '200%' }}
              exit={{ translateX: '200%' }}
              animate={{ translateX: '0%' }}
              className="mb-2"
            >
              <Notification
                icon={notification.icon}
                title={notification.title}
                message={notification.body}
                disappearAfter={notification.disappearAfter}
                classNames={notification.classNames}
                onClick={notification.onClick}
                onClose={() =>
                  removeAt(
                    notifications.findIndex(
                      (currentNotification) => notification.key === currentNotification.key
                    )
                  )
                }
              />
            </m.div>
          ))}
        </AnimatePresence>
      </div>
    </AppNotificationContext.Provider>
  );
};
