import { addMinutes, format, getYear } from 'date-fns';

export const formatCurrency = (cents: number, showCents = false) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: showCents ? 2 : 0,
    minimumFractionDigits: showCents ? 2 : 0,
  });

  return formatter.format(cents / 100);
};

export const formatCurrencyNotCent = (cents: number, showCents = false) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: showCents ? 2 : 0,
    minimumFractionDigits: showCents ? 2 : 0,
  });

  return formatter.format(cents);
};

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(num);
};

export const convertCurrencyStringToCents = (currencyStr: string) => {
  if (currencyStr === '$') {
    return 0;
  }

  return Math.round(+currencyStr.replace(',', '').replace('$', '') * 100);
};

export const convertCurrencyStringToNumber = (currencyStr?: string) => {
  if (currencyStr === undefined || currencyStr === '' || currencyStr === '$') {
    return undefined;
  }

  return +currencyStr.replace(',', '').replace('$', '');
};

export const formatNumberCompact = (num: number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return formatter.format(num);
};

export const deformatPhoneNumber = (phone?: string) => {
  return (
    phone?.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('+1', '') || ''
  );
};

export const formatPhoneNumber = (phone: string) => {
  const deformattedPhoneNumber = deformatPhoneNumber(phone);

  return `+1 (${deformattedPhoneNumber.slice(0, 3)}) ${deformattedPhoneNumber.slice(
    3,
    6
  )} - ${deformattedPhoneNumber.slice(6)}`;
};

export const formatDate = (date: Date, adjustForTz?: boolean) => {
  const adjustedDate = adjustForTz ? addMinutes(date, date.getTimezoneOffset()) : date;

  if (getYear(adjustedDate) !== getYear(new Date())) {
    return format(adjustedDate, 'MMM d, yyyy');
  }

  return format(adjustedDate, 'MMMM d');
};
