import * as snippet from '@segment/snippet';

import Script from 'next/script';
import { isProd } from '@/utils/app';

function renderSegmentSnippet() {
  // Segment setup. See https://github.com/vercel/next.js/blob/canary/examples/with-segment-analytics/pages/_app.js
  const opts: snippet.Options = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (!isProd()) {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

const SegmentScript = () => {
  return (
    <Script
      async
      id="segment-script"
      dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
    />
  );
};

export default SegmentScript;
