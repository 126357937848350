import React from 'react';

export interface TailwindBreakpointProps {
  positionClasses?: string;
}

const TailwindBreakpoint: React.FC<TailwindBreakpointProps> = ({
  positionClasses = 'bottom-1 left-1',
}) => {
  const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];

  const breakpointClasses: any = {
    xs: 'block sm:hidden',
    sm: 'hidden sm:block md:hidden',
    md: 'hidden md:block lg:hidden',
    lg: 'hidden lg:block xl:hidden',
    xl: 'hidden xl:block 2xl:hidden',
    '2xl': 'hidden 2xl:block',
  };

  return (
    <div
      className={`fixed ${positionClasses} z-50 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white`}
    >
      {breakpoints.map((bp) => (
        <div key={bp} className={breakpointClasses[bp]}>
          {bp}
        </div>
      ))}
    </div>
  );
};

export default TailwindBreakpoint;
