import '@fortawesome/fontawesome-svg-core/styles.css';

import React, { useEffect } from 'react';

import { AppNotificationProvider } from '@/context/AppNotificationContext';
import { LazyMotion } from 'framer-motion';
import { usePrevious } from 'react-use';
import { useRouter } from 'next/router';

const loadFramerMotion = () => import('@/vendor/framer').then((res) => res.default);
const loadFontAwesome = () => import('@fortawesome/fontawesome-svg-core').then((mod) => mod.config);

const Page: React.FC = ({ children }) => {
  const router = useRouter();

  // Recommended approach for using FontAwesome with Next.js:
  // https://fontawesome.com/docs/web/use-with/react/use-with
  useEffect(() => {
    loadFontAwesome().then((config) => (config.autoAddCss = false));
  }, []);

  const previousPath = usePrevious(router.pathname);
  useEffect(() => {
    if (previousPath !== undefined && previousPath !== router.pathname) {
      window.analytics?.page();
    }
  }, [previousPath, router.pathname]);

  return (
    <LazyMotion features={loadFramerMotion} strict>
      <AppNotificationProvider>{children}</AppNotificationProvider>
    </LazyMotion>
  );
};

export default Page;
